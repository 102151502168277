import React from "react";
import "../assets/styles/AboutUs.css";
import l1 from '../assets/images/people looking 17d41ecb-2be5-41ba-8424-bf0b5821cc8b.png';
/*import l2 from '../assets/images/people looking 3556d6ff-f05e-4ef8-8dc4-902f5a838658.png';*/

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <h1>About Us</h1><br></br>
      <div className="info-container">
        <div className="info-section">
          
        <h9><p>
          <b><i>Sy9 Network</i></b> is a self-service advertising portal for digital ads over
          a variety of digital ad publishing platforms, primarily over a network
          of digital screens but not exclusively limited to digital screens as the 
          medium of advertising.</p></h9>
                             
        </div>
        
        </div>
      
        
        <div className="info-container">
          <div className="container1">
        
        
        <div className="section1">
        <br></br><h9><p>
          Our algorithms crunch numbers, analyze trends, and serve 
          up actionable insights. We guide your ad placements based on
          real-time data, ensuring optimal results.</p></h9>
        
        <br></br><h9><p>We collaborate with advertisers, media owners, and tech 
          enthusiasts. Together, we shape the future of digital advertising. The digital landscape evolves, and so do we. From dynamic content
          to programmatic buying, we stay ahead of the curve.
          </p></h9>
          </div>

          <div className="section1">
          <div className="imageclass">
            <img src={l1} alt=" " className="img1"/>
    
         </div>
          </div>
          </div>
          </div>
        
          <div className="info-container">
        
        <div className="info-section">
                   
        <h9><p>
          The goal of Sy9 Network is to democratize the
          digital advertising ecosystem, by providing the whole spectrum of digital
          advertising; digital advertisers, and digital ad space media, an equal
          franchise to participate, thereby contributing to the progress of the
          digital ad economy.
          </p></h9>       
          </div>
        </div>
    
      {/*<h9><p>
        Ready to be part of the revolution? Join the <a>Sy9 Network</a> 
        Whether you're an advertiser seeking impact or a media owner 
        ready to monetize, Sy9 Network welcomes you.
        Let's create meaningful connections in the digital realm.
      </p></h9>*/}
       
    </div>
        
  
    
  );
};
export default AboutUs;
