import React from "react";
import "../assets/styles/page1.css";
import l1 from '../assets/images/people looking e21c403b-2a1f-4968-9ccd-b085012fa833.png';

const Page1 = () => {
    return (
      <div className="page1-container">
        <div className="container1">
          <div className="section1">
            <div className="imageclass1">
              <img src={l1} alt=" " className="img2"/>
            </div>
          </div>
          <div className="section1">
            <div className="textsection">
              <p1><p><h1><b>Monetize your digital screen and generate revenue</b></h1></p>
              <p><h6> Do you own a digital screen? Have space for digital Ads? Register to Sy9 Network, rent out your digital ad space and make money. </h6></p></p1>
              <ul><a class="button button1" href="https://fun.sy9.network/#loginform">Get Started</a></ul>
              </div> 
           </div>                 
        </div>
      </div>
    );
  };
  export default Page1;
  
