import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import NavigationBar from "./container/NavigationBar";
import Banner from "./Components/Banner";
import Testimonials from "./Components/Testimonials";
import Products from "./Components/Products";
import "./assets/styles/Banner.css";
import "./assets/styles/Home.css";
import AboutUs from "./Components/AboutUs";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Page1 from "./Components/Page1";
import Page2 from "./Components/Page2";
import Vision from "./Components/vision";


function App() {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const bannerRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsHeaderVisible(entry.isIntersecting);
      },
      {
        root: null, // Observe within the viewport
        threshold: 0, // Trigger when even 1 pixel of the banner is visible
      }
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, []);
  return (
    
    <div className="App">
      <NavigationBar />
      <div className="page-container">
        <section id="banner" className="section">
          <Banner />
        </section>

        <section id="page1" className="section">
          <Page1 />
        </section>

        <section id="page2" className="section">
          <Page2 />
        </section>

        <section id="aboutUs" className="section">
          <AboutUs />
        </section>

        <section id="Vision" className="section">
          <Vision />
        </section>

        {/*<section id="featured-products" className="section">
          <h2>Featured Products</h2>
          <Products />
        </section>
        
        <section id="testimonials" className="section">
          <h2>Testimonials</h2>
          <Testimonials />
        </section>*/}

        <section id="Contact" className="section">
          <h2>Contact Us</h2>
          <Contact />
        </section>

        <section id="Footer" className="footerSection">
          <Footer />
        </section>
      </div>
    </div>
  );
 /* return (
    <div className="anim">
      <Suspense fallback={<div>Loading...</div>}>
        <AnimationCanvas />
      </Suspense>
    </div>
  );*/
}

export default App;
