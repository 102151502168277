import React from "react";
import { Navbar, Nav, NavItem, NavLink, NavDropdown } from "react-bootstrap";
import logo from "../assets/images/logo.png";

const NavigationBar = ({ isHeaderVisible }) => {
  return (
    <nav className={`navbar ${isHeaderVisible ? '' : 'navbar-hidden'}`}>
      <Navbar bg="myColor" variant="dark" expand="lg">
        <Navbar.Brand href="/">
          <img
            src={logo}
            height="42"
            className="d-inline-block align-top logo"
            alt="Logo"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto justify-content-between justify-content-lg-start">
            <NavItem>
              <NavLink href="https://fun.sy9.network"><b>Publish Ad</b></NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://fun.sy9.network/post-display.php?id=1"><b>Add Screen</b></NavLink>
            </NavItem>
            <NavDropdown title={<b>About</b>} id="about-dropdown">
              <NavDropdown.Item href="#aboutUs">About Us</NavDropdown.Item>
              <NavDropdown.Item href="#Vision">Our Vision</NavDropdown.Item>
              <NavDropdown.Item href="#Contact">Contact Us</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="justify-content-end">
            <NavItem>
              <NavLink href="https://fun.sy9.network/#loginform">Login</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://fun.sy9.network/contact-us.php">Signup</NavLink>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </nav>
  );
};

export default NavigationBar;
