import React from "react";
import "../assets/styles/page2.css";
import l1 from '../assets/images/Sy91.jpg';


const Page2 = () => {
    return (
      <div className="page2-container">
        <div className="container2">
        <div className="section2">
        <div className="imageclass2">
              <img src={l1} alt=" " className="img3"/>
        </div>
        </div>
        <div className="section2">  
        <div className="textsection1">
            <p1><p><h1><b>Run an efficient and effective Ad Campaign</b></h1></p>
            <p><h6> Choose from the variety of numerous digital media available on the Sy9 Network. Customize publishing with the help of our data driven advisory suiting your requirement, and get best outreach for every penny spent. </h6></p></p1>
            <ul><a class="button button1" href="https://fun.sy9.network/#loginform">Lets Go!</a></ul>
        </div> 
        </div>
        
       </div>
      </div>
    );
  };
  export default Page2;
  
