import React from "react";

const Banner = () => {
  return (
    <>
      <h1>
        Welcome to <span>Sy9 Network</span>
      </h1>
      
      <p>
        The New Frontier in Digital Advertising
      </p>
      {/*<div className="offers">
        <div className="offer">
          <h4>Publish Ad</h4>
          <p><i><h6>Publish an Ad over our vast network of displays for an effective outreach</h6></i></p>
        </div>
        <div className="offer">
          <h4>Monetize Screen</h4>
          <p><i><h6>Monetize your digital screen by registering it on our network</h6></i></p>
        </div>
        <div className="offer">
          <h4>Monetize Wall Spaces</h4>
          <p><i><h6>Monetize you available real estate by allocatting the space to digital screens</h6></i></p>
        </div>
      </div>*/}
      
      <div className="pgbtm" ><a href="#page1">&#9166;</a></div>
      
    </>
  );
};

export default Banner;
