import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../assets/styles/Footer.css";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"; 

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const socialLinks = [
    {
      icon: faFacebook,
      link: "https://www.facebook.com/people/Sy9-Network/61561843151334/",
    },
    {
      icon: faTwitter,
      link: "https://www.twitter.com/sy9network",
    },
    {
      icon: faInstagram,
      link: "https://www.instagram.com/sy9network",
    },
    // Add objects for other social media links following the same structure
    // { icon: faYoutube, link: 'https://www.youtube.com/' },
    // { icon: faLinkedin, link: 'https://www.linkedin.com/' },
  ];

  return (
    <footer className="footer">
      <div className="container">
        <div className="social-media">
          {socialLinks.map((socialLink) => (
            <a
              href={socialLink.link}
              target="_blank"
              rel="noopener noreferrer"
              key={socialLink.icon} // Key for improved performance
            >
              <FontAwesomeIcon icon={socialLink.icon} />
            </a>
          ))}
        </div>
        <p className="copyright">
          &copy; {currentYear} <span>SSMind5 Technologies Pvt Ltd </span>. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
