import React from "react";
import "../assets/styles/vision.css";


const Vision = () => {
  return (
    <div className="vison-container">
         <h1>Our Vision</h1>
      <div className="container3">
         
      <div className="section3">
         
          <h7><ul>
          We envision a world where every advertiser, regardless of size, has an equal franchise to participate. By providing transparent opportunities, we contribute to the progress of the entire industry. 
          </ul><ul>It's not just about ads; it's about empowerment.
          </ul></h7>
          </div>
        </div>

        </div>
  
    
  );
};
export default Vision;
